import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
color: ${(props) => props.theme.text};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHERE CAN I VIEW MY NFTS?" >
  Once minted or bought simply connect to your OpenSea account to view your NFTs.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="HOW MANY NFTs WILL BE AVAILABLE AND WHATS THE PRICE?" >
  916 will be the initial total supply. The mint price will be .073 ETH. There is no limit to the number you can mint. First come, first served. No whitelist.

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHY DO WE NEED ROYALTIES?" >
  The amount of royalties is fixed at 7.5% to finance the Justice Tree projects, initiatives and giveaways. We have the ambition to organize multiple events around the US in order to build awareness of community violence so we can influence positive policy changes at the local, state, and federal government level.
  </Accordion>
</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="HOW CAN I USE MY NFT?" >
You will be able to receive product discounts at participating dispensaries in California for now. The discount may be expanded in the future as adoption and collaborations increase. NFT numbers will be used within raffles and drawn at random based on the raffle schedule(TBD)
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHAT ARE THE CHARITIES THAT WILL RECEIVE DONATIONS?
" >
IMSAC Events, South Sacramento Homeless Assistance Resource Team, and Bless Child Neighborhood Association. Payouts will occur upon minting out all NFT's. 
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHEN CAN WE MINT?" >
Sept 28th will be launch date in Las Vegas @ The MJ Unpacked 2022
  </Accordion>
</Box>
    </Container>
    </Section>
  )
}

export default Faq